.user-statistics-page {
  .date-range-picker-wrapper {
    .rdrDateRangePickerWrapper {
      border: solid 1px rgb(0, 0, 0);
    }
  }
  .chart-wrapper {
    height: 300px;
    margin-top: 10px;
  }
  .list-wrapper {
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
