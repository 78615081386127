.users-list-page {
  .page-tools {
    margin-bottom: 10px;
  }
  .contents {
    table {
      thead {
        td {
          padding: 5px;
        }
      }
      .user-list-item {
        td {
          cursor: pointer;
          &.username,
          &.handle,
          &.name,
          &.email {
            width: 170px;
          }
          &.email-verified {
            width: 120px;
          }
          &.credit {
            width: 120px;
          }
          &.created-at {
            width: 150px;
          }
          &.group {
            width: 200px;
          }
        }
        &.selected {
          td {
            background-color: #adadad;
          }
        }
      }
      .developer-badge {
        visibility: hidden;
        margin: 5px;
      }
      .staff-badge {
        visibility: hidden;
        margin: 5px;
      }
      .developer-user {
        .developer-badge {
          visibility: visible;
        }
      }
      .staff-user {
        .staff-badge {
          visibility: visible;
        }
      }
    }
  }
}
