.asset-list-item {
  cursor: pointer;
  .thumbnails {
    display: flex;
    .item {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .id {
    width: 200px;
  }
  .username {
    width: 200px;
  }
  .created-at,
  .app-name {
    width: 150px;
  }
  .rating,
  .quantity {
    width: 80px;
  }
  .option {
    width: 130px;
  }
}
