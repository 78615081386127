.dashboard-page {
  .contents {
    .action-list {
      .action-item {
        width: 100%;
        display: flex;
        .action-name {
          font-weight: 600;
          margin-right: 30px;
        }
      }
    }
    .queue-size-render-box {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      .card {
        width: 100%;
      }
      .btn {
        margin-top: 5px;
      }
    }
    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 30px;
    }
  }
}

.tools-render {
  .TASKS2,
  .TASKS {
    position: relative;
    z-index: 1;
    .background-bar {
      position: absolute;
      height: 100%;
      z-index: -1;
      opacity: .6;
    }
    &.PROD {
      .background-bar {
        background-color: #81a1da;  
      }
    }
    &.DEV {
      .background-bar {
        background-color: #be819c;
      }
    }
    &.LONG {
      .background-bar {
        width: 100%;
      }
    }
    &.SHORT {
      .background-bar {
        width: 20%;
      }
    }
  }
}
