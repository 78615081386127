.main-container {
  padding: 0px;
  .layout-wrapper {
    display: flex;
    .page-content-wrapper {
      width: 100%;
      padding: 40px 30px;
      padding-left: 300px;
      transition: all 0.25s ease-out;
      &.collapsed {
        padding-left: 110px;
      }
      .page-content-body {
        position: relative;
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    .layout-wrapper {
      .page-content-wrapper {
        padding: 20px 10px;
        padding-left: 90px !important;
      }
    }
  }
}

.page-header {
  margin-bottom: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
  }
  .buttons {
    .btn {
      margin-right: 15px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

a {
  text-decoration: none;
  color: initial;
}

.user-popover {
  .popover-arrow {
    display: none;
  }
  padding: 5px;
}

.statistic-item {
  margin-bottom: 20px;
}

.file-uploader {
  .container {
    border: solid 1px rgba(0, 0, 0, 0.125);
    border-radius: 6px;
    padding: 15px 10px;
    .dropzone {
      padding: 30px 0px;
      border-radius: 6px;
      border: 1px dashed rgba(0, 0, 0, 0.125);
      text-align: center;
      cursor: pointer;
    }
    aside {
      padding: 20px 0px;
      h4 {
        font-weight: 600;
      }
    }
  }
}

.text-word-break {
  word-break: break-all;
}

.color-label {
  border-radius: 10px;
  color: #fff;
  text-align: center;
  padding: 10px;
  .label {
    margin-bottom: 5px;
  }
  .value {
    font-weight: 500;
    font-size: 20px;
  }
  &.purple {
    background-color: rgb(102, 51, 153);
  }
  &.blue {
    background-color: rgb(72, 113, 247);
  }
}

.user-credit-history-dialog {
  .modal-body {
    max-height: 75vh;
    overflow: scroll;
  }
}

.date-range-picker-wrapper {
  .rdrDateRangePickerWrapper {
    border: solid 1px rgb(0, 0, 0);
  }
}

.custom-tooltip {
  background: rgba(255, 255, 255, 0.9);
  border: solid 1px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 6px;
  .label {
    margin: 0px 5px 0px 0px;
  }
}
.custom-legend {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .legend-item {
    display: flex;
    font-size: 12px;
    align-items: center;
    .color-box {
      width: 8px;
      height: 8px;
      border-radius: 1px;
      margin-right: 5px;
    }
  }
}
.recharts-rectangle {
  &.recharts-tooltip-cursor {
    fill: rgba(0, 0, 0, 0.3);
  }
}