.display-card-item {
  width: 150px;
  text-align: center;
  cursor: pointer;
  .card-header {
    padding: 5px;
    border: none;
    padding-bottom: 0;
  }
  .card-body {
    padding: 5px;
    p {
      font-size: 48px;
      line-height: 1;
      margin: 0;
    }
  }
}
