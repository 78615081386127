.detail-item {
  margin-bottom: 20px;
  h6 {
    font-size: 0.75rem;
    line-height: 1.3;
    margin: 0px;
    opacity: 0.6;
  }
  p {
    margin: 0px;
  }
}
