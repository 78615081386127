.media-list-item {
  font-size: 12px;
  .badge {
    color: #fff;
  }
  .thumbnail {
    width: 80px;
    img {
      width: 80px;
      max-height: 100px;
    }
  }
  .title {
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .id {
    width: 250px;
  }
  .batch-id {
    width: 250px;
  }
  .user-id {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .updated-at {
    width: 200px;
  }
  .processing-status {
    width: 200px;
  }
  .visibility {
    width: 200px;
  }
  .motion-types {
    width: 150px;
  }
  .rating {
    width: 60px;
    span {
      width: 100%;
      text-align: center;
    }
  }
}
