.session-detail-page {
  .page-header {
    .actions {
      display: flex;
      .btn {
        margin-left: 15px;
      }
    }
  }
  .vimmerse-media-detail {
    margin-bottom: 20px;
  }
  
  .success-content-viewer {
    h5 {
      font-size: 1rem;
    }
  }

  .uploaded-files-list {
    margin-bottom: 30px;
    .card {
      .files-list {
        list-style: none;
        padding: 0px;
        .list-group-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .file-name {
            word-break: break-all;
          }
          .buttons {
            display: flex;
            .file-type-option {
              width: 150px;
            }
            .btn {
              margin-left: 5px;
              &:first-child {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
}
