.customer-detail-page {
  .container {
    .row {
      margin-bottom: 20px;
    }
  }
  .chart-wrapper {
    width: 100%;
    height: 600px;
  }
}
