.global-status-bar {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 3;
  padding: 5px;
  ul {
    margin-bottom: 0px;
    li {
      list-style: none;
    }
  }
}
