.batch-list-item {
  font-size: 12px;
  .id {
    width: 200px;
  }
  .email {
    width: 150px;
  }
  .owner {
    width: 80px;
  }
  .status {
    width: 80px;
  }
  .notified-at {
    width: 100px;
  }
  .created-at {
    width: 100px;
  }
  .uploaded {
    width: 100px;
  }
  .selected {
    width: 100px;
  }
  .paid-amount {
    width: 100px;
  }
  .name {
    width: 100px;
  }
  .title {
    width: 100px;
  }
  .industry {
    width: 100px;
  }
  .company {
    width: 100px;
  }
}
