.customers-list-page {
  .page-tools {
    margin-bottom: 10px;
  }
  .contents {
    table {
      tr {
        td {
          cursor: pointer;
        }
      }
    }
  }
}
