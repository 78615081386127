.sidebar-wrapper {
  height: 100vh !important;
  transition: margin 0.25s ease-out;
  position: fixed;
  z-index: 20;
  .ps-sidebar-root {
    height: 100vh;

    .ps-sidebar-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      color: rgba(255, 255, 255, 0.8);
      .sidebar-header {
        display: flex;
        padding: 10px;
        justify-content: space-between;
        border-bottom: solid 1px #d8d8d8;
        h3 {
          margin-bottom: 0px;
          padding: 0px 15px;
        }
        .env-selector {
          width: 100%;
          color: #1d1d1d;
        }
      }
      .ps-menu-root {
        .ps-menuitem-root {
          color: rgba(255, 255, 255, 0.8);
          &:hover {
            color: #1d1d1d;
          }
        }
      }
      .sidebar-footer {
        padding: 10px;
        border-top: solid 1px #d8d8d8;
        margin-top: auto;
      }
    }
  }
}
